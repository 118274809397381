import React from "react";

import {
  Box,
  H1,
  H6,
  PrismicImage,
  SectionWrapper,
  Text,
  FlexRow,
  Avatar,
  SplitHeader
} from "src/components";

const Intro = ({
  title,
  image,
  author,
  authorImage,
  date,
  setInvert,
  setInvertLogo
}) => (
  <>
    <SplitHeader
      left={
        <>
          {image && (
            <Box position="relative" bg="bg.reverse">
              <PrismicImage image={image} alt={title && title} />
            </Box>
          )}
        </>
      }
      right={
        <div
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: 100%;
            ${props => props.theme.mediaQueries.large} {
              align-items: flex-start;
              text-align: left;
            }
          `}
        >
          <H6 children={`Article`} color="text.reverse" />
          {title && <H1 children={title} mt={4} color="text.reverse" />}
          {author && (
            <FlexRow justifyContent="flex-start" alignItems="center" mt={3}>
              {authorImage && authorImage.url && <Avatar image={authorImage} />}
              <Box flex={1} ml={authorImage && authorImage.url && 2}>
                <Text fontSize={[0, 1]} color="text.reverse">
                  {author && author}
                  {date && <span> · {date}</span>}
                </Text>
              </Box>
            </FlexRow>
          )}
        </div>
      }
      color="brand.altPrimary"
      invert={true}
      setInvertLogo={setInvertLogo}
      setInvert={setInvert}
    />
  </>
);

export default Intro;
