import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";
import { RichText, SectionWrapper } from "src/components";

const Text = ({ text }) => (
  <>
    {text && (
      <SectionWrapper id="text" maxWidth={0} pb={[6, 8]}>
        <RichText fontSize={[1, 2]} color="text.body">
          {PrismicRichText.render(text, linkResolver, serializer)}
        </RichText>
      </SectionWrapper>
    )}
  </>
);

export default Text;
