import React from "react";
import Masonry from "react-masonry-component";

import { Box, PrismicImage, SectionWrapper, Text } from "src/components";

const Gallery = ({ sliceIndex, items }) => (
  <SectionWrapper id="gallery" maxWidth={2} pb={[6, 8]}>
    <Box m={-3}>
      <Masonry
        options={{
          transitionDuration: 0
        }}
      >
        {items.map((item, index) => (
          <Box
            width={[1, 1 / 2]}
            key={"sliceGallery" + sliceIndex + "item" + index}
            p={3}
          >
            <PrismicImage image={item.image && item.image.url && item.image} />
            {item.caption && (
              <Text
                children={item.caption}
                mt={2}
                fontSize={[0, 1]}
                color="text.body"
              />
            )}
          </Box>
        ))}
      </Masonry>
    </Box>
  </SectionWrapper>
);

export default Gallery;
