import React from "react";

import { Box, H6, SectionWrapper, Text } from "src/components";

const Quote = ({ quote, author }) => (
  <SectionWrapper id="quote" maxWidth={0} pb={[6, 8]}>
    <Box pl={[5, 7]} borderLeft="1px solid black">
      <Text
        children={quote}
        fontSize={[3, 4]}
        css={`
          position: relative;
          &::before {
            content: "“";
            position: absolute;
            transform: translateX(-100%);
          }
          &::after {
            content: "”";
          }
        `}
      />
      {author && <H6 children={`– ` + author} mt={3} />}
    </Box>
  </SectionWrapper>
);

export default Quote;
