import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import moment from "moment";

import { Box, SEO } from "src/components";

import Gallery from "./_components/_gallery";
import Intro from "./_components/_intro";
import Image from "./_components/_image";
import Quote from "./_components/_quote";
import Text from "./_components/_text";

function PostPage({ data, setInvert, setInvertLogo, preview }) {
  let post = {};
  if (data) {
    if (data.post) {
      if (data.post.dataString) {
        post = JSON.parse(data.post.dataString);
        if (preview) {
          post = preview;
        }
        data.authors.edges.forEach(author => {
          if (post.author) {
            if (author.node.prismicId === post.author.id) {
              post.authorData = JSON.parse(author.node.dataString);
            }
          }
        });
      }
    }
  } else {
    if (preview) {
      post = preview;
    }
  }
  return (
    <>
      <SEO
        title={post.title && RichText.asText(post.title)}
        image={post.image && post.image.url && post.image.url}
        imageAlt={post.image.alt && post.image.alt}
      />
      <Intro
        title={post.title && RichText.asText(post.title)}
        image={post.image && post.image.url && post.image}
        authorImage={post.authorData && post.authorData.picture}
        author={
          post.authorData &&
          post.authorData.name &&
          RichText.asText(post.authorData.name)
        }
        date={post.date && moment(post.date).format("MMMM D, YYYY")}
        setInvert={setInvert}
        setInvertLogo={setInvertLogo}
      />
      <Box as="article" pt={[6, 8]}>
        {post.body.map((slice, index) => {
          switch (slice.slice_type) {
            case "text":
              return (
                <Text text={slice.primary.text} key={"postSlice" + index} />
              );
            case "quote":
              return (
                <Quote
                  quote={slice.primary.quote}
                  author={slice.primary.author}
                  key={"postSlice" + index}
                />
              );
            case "gallery":
              return <Gallery items={slice.items} key={"postSlice" + index} />;
            case "image":
              return (
                <Image
                  image={slice.primary.image.url && slice.primary.image}
                  type={slice.primary.image_style}
                  caption={slice.primary.caption}
                  key={"postSlice" + index}
                />
              );
            default:
              return null;
          }
        })}
      </Box>
    </>
  );
}

export default PostPage;

export const query = graphql`
  query($id: String!) {
    post: prismicPost(id: { eq: $id }) {
      prismicId
      dataString
    }
    authors: allPrismicStaffMember {
      edges {
        node {
          prismicId
          dataString
        }
      }
    }
  }
`;
