import React from "react";

import { PrismicImage, SectionWrapper, Text } from "src/components";

const Image = ({ image, type, caption }) => (
  <>
    {type !== "Inline" && (
      <SectionWrapper id="full-image" maxWidth={2} pb={[6, 8]}>
        <PrismicImage
          image={image}
          css={`
            width: 100%;
          `}
        />
        {caption && (
          <Text
            children={caption}
            mt={2}
            fontSize={[0, 1]}
            textAlign="center"
            color="text.body"
          />
        )}
      </SectionWrapper>
    )}
    {type === "Inline" && (
      <SectionWrapper id="inline-image" maxWidth={0} pb={[6, 8]}>
        <PrismicImage
          image={image}
          css={`
            width: 100%;
          `}
        />
        {caption && (
          <Text
            children={caption}
            mt={2}
            fontSize={[0, 1]}
            textAlign="center"
            color="text.body"
          />
        )}
      </SectionWrapper>
    )}
  </>
);

export default Image;
